/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useState } from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
  NiagaraToggle,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Divider, Modal } from '@scuf/common';
import './ConfigureNotificationsModal.scss';

interface IConfigureNotificationsModal {
  onClose: () => void;
}

export function ConfigureNotificationsModal({ onClose }: IConfigureNotificationsModal) {
  const [notificationServiceAccount, setNotificationServiceAccount] = useState(false);
  const [notificationSubscription, setNotificationSubscription] = useState(false);

  return (
    <NiagaraModal className='configuration-notifications-modal' open={true} size='large'>
      <Modal.Header>
        <div className='modal-heading'>Configure Notifications</div>
        <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <div className='notification-type-wrapper'>
          <div className='notification-type'>
            <div className='notification-type-header'>Notification Type</div>
            <Divider fitted={true} />
            <div className='notification-item'>
              <div className='notification-item-name'>Service account updates</div>
              <div>
                <NiagaraToggle
                  disabled={false}
                  checked={notificationServiceAccount}
                  onChange={() => {
                    setNotificationServiceAccount(!notificationServiceAccount);
                  }}
                />
              </div>
            </div>
            <div className='notification-item'>
              <div className='notification-item-name'>Subscription updates</div>
              <div>
                <NiagaraToggle
                  disabled={false}
                  checked={notificationSubscription}
                  onChange={() => {
                   setNotificationSubscription(!notificationSubscription);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className='button-container'>
          <NiagaraButton2 content='Cancel' type='secondary' onClick={() => onClose()} />
          <NiagaraButton2 content='Save' type='primary' />
        </div>
      </Modal.Footer>
    </NiagaraModal>
  );
}
