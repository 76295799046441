/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
  NiagaraToggle,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Divider, Loader, Modal } from '@scuf/common';
import './ConfigureNotificationsModal.scss';
import { notificationSettingsMockData } from './mockData';
import { useUserRoleStore } from '../../../userroles/UseUserRoleStore';
import { getNotificationSettingsAsync, NotificationSettings } from '../../../api/notifications';
import { ApiError } from '../../../utils/niagaraHttp';
import { showErrorToast } from '../../../toast/Toast';

interface IConfigureNotificationsModal {
  onClose: () => void;
}

export function ConfigureNotificationsModal({ onClose }: IConfigureNotificationsModal) {
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettings[]>();
  const [isLoading, setLoading] = useState(false);
  const {
    currentUser: { id: userId },
  } = useUserRoleStore();

  useEffect(() => {
    //  TO DO: will be uncommented once the api is available
    //getNotificationSettings(userId);
  }, []);
  const getNotificationSettings = (userId: number) => {
    if (!userId) return;
    setLoading(true);
    getNotificationSettingsAsync(userId)
      .then((response) => {
        setNotificationSettings(response);
      })
      .catch((_: ApiError) => {
        showErrorToast('An error occurred while fetching notification details.');
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

   const [notificationServiceAccount, setNotificationServiceAccount] = useState(
     notificationSettingsMockData.find((settings) => settings.eventTypeId === 2)?.subscribe
   );

   const [notificationSubscription, setNotificationSubscription] = useState(
     notificationSettingsMockData.find((settings) => settings.eventTypeId === 1)?.subscribe
   );

  return (
    <NiagaraModal className='configuration-notifications-modal' open={true} size='large'>
      <Modal.Header>
        <div className='modal-heading'>Configure Notifications</div>
        <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={isLoading} overlayOpacity={0.5}>
          <div className='notification-type-wrapper'>
            <div className='notification-type'>
              <div className='notification-type-header'>Notification Type</div>
              <Divider fitted={true} />
              <div className='notification-item'>
                <div className='notification-item-name'>Service account updates</div>
                <div>
                  <NiagaraToggle
                    disabled={false}
                    checked={notificationServiceAccount as boolean}
                    onChange={() => setNotificationServiceAccount(!notificationServiceAccount)}
                  />
                </div>
              </div>
              <div className='notification-item'>
                <div className='notification-item-name'>Subscription updates</div>
                <div>
                  <NiagaraToggle
                    disabled={false}
                    checked={notificationSubscription as boolean}
                    onChange={() => setNotificationSubscription(!notificationSubscription)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <div className='button-container'>
          <NiagaraButton2 content='Cancel' type='secondary' onClick={() => onClose()} />
          <NiagaraButton2 content='Save' type='primary' />
        </div>
      </Modal.Footer>
    </NiagaraModal>
  );
}
