/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

export const RETRIEVAL_COLOR = '#2376E3';
export const LINE_COLOR_DARK = '#68D560';
export const LINE_COLOR_LIGHT = '#159D62';
export const RETRIEVAL_CHART_POINT_WIDTH = 20;
export const RETRIEVAL_CHART_LINE_COLOR = '#A0A0A0';
export const DARK_MODE_FONT_COLOR = '#F0F0F0';
export const RETRIEVAL_CHART_FONT_STYLE = {
  fontSize: '14px',
  color: '#606060',
};

export const RETRIEVAL_CHART_FONT_STYLE_DARK = {
  fontSize: '14px',
  color: DARK_MODE_FONT_COLOR,
};
export const RETRIEVAL_CHART_LAYOUT = {
  style: {
    height: '290px',
    width: '100%',
  },
};
export const RETRIEVAL_USAGE_BASELINE = 100;
export const RETRIEVAL_USAGE_WARNING_LIMT = 80;
export const RETRIEVAL_USAGE_WARNING_HEADER = 'Usage Warning';
export const RETRIEVAL_USAGE_LIMIT_EXCEEDED_HEADER = 'Usage Limit Exceeded';
export const RETRIEVAL_USAGE_WARNING_SUB_HEADER = 'Data usage is approaching the limit';
