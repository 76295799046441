import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.197 1.28033C14.4899 0.987437 14.4899 0.512563 14.197 0.21967C13.9041 -0.0732233 13.4293 -0.0732233 13.1364 0.21967L10.1667 3.18934L8.94703 1.96967C8.65414 1.67678 8.17927 1.67678 7.88637 1.96967C7.59348 2.26256 7.59348 2.73744 7.88637 3.03033L9.63637 4.78033C9.77703 4.92098 9.96779 5 10.1667 5C10.3656 5 10.5564 4.92098 10.697 4.78033L14.197 1.28033ZM1.125 11.2501C0.641751 11.2501 0.25 11.6419 0.25 12.1251C0.25 12.6084 0.641751 13.0001 1.125 13.0001H13.375C13.8582 13.0001 14.25 12.6084 14.25 12.1251C14.25 11.6419 13.8582 11.2501 13.375 11.2501H1.125ZM1.125 6.86342C0.641751 6.86342 0.25 7.25517 0.25 7.73842C0.25 8.22167 0.641751 8.61342 1.125 8.61342H13.375C13.8582 8.61342 14.25 8.22167 14.25 7.73842C14.25 7.25517 13.8582 6.86342 13.375 6.86342H1.125ZM1.125 2.50011C0.641751 2.50011 0.25 2.89186 0.25 3.37511C0.25 3.85836 0.64175 4.25011 1.125 4.25011H4.04167C4.52492 4.25011 4.91667 3.85836 4.91667 3.37511C4.91667 2.89186 4.52492 2.50011 4.04167 2.50011H1.125Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0996 1.28033C16.3924 0.987437 16.3924 0.512563 16.0996 0.21967C15.8067 -0.0732233 15.3318 -0.0732233 15.0389 0.21967L11.6163 3.64227L10.1702 2.19614C9.87726 1.90324 9.40239 1.90324 9.1095 2.19614C8.8166 2.48903 8.8166 2.9639 9.1095 3.2568L11.086 5.23326C11.2266 5.37391 11.4174 5.45293 11.6163 5.45293C11.8152 5.45293 12.006 5.37391 12.1466 5.23326L16.0996 1.28033ZM1.40474 12.6088C0.858951 12.6088 0.416504 13.0512 0.416504 13.597C0.416504 14.1428 0.858951 14.5853 1.40474 14.5853H15.24C15.7858 14.5853 16.2282 14.1428 16.2282 13.597C16.2282 13.0512 15.7858 12.6088 15.24 12.6088H1.40474ZM1.40474 7.65442C0.858951 7.65442 0.416504 8.09687 0.416504 8.64266C0.416504 9.18844 0.858951 9.63089 1.40474 9.63089H15.24C15.7858 9.63089 16.2282 9.18844 16.2282 8.64266C16.2282 8.09687 15.7858 7.65442 15.24 7.65442H1.40474ZM1.40474 2.72647C0.858951 2.72647 0.416504 3.16891 0.416504 3.7147C0.416504 4.26048 0.858952 4.70293 1.40474 4.70293H4.69885C5.24463 4.70293 5.68708 4.26048 5.68708 3.7147C5.68708 3.16891 5.24463 2.72647 4.69884 2.72647H1.40474Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0006 1.28033C18.2934 0.987437 18.2934 0.512563 18.0006 0.21967C17.7077 -0.0732233 17.2328 -0.0732233 16.9399 0.21967L12.996 4.16359L11.2892 2.4568C10.9963 2.1639 10.5214 2.1639 10.2285 2.4568C9.93562 2.74969 9.93562 3.22456 10.2285 3.51746L12.4656 5.75458C12.7585 6.04747 13.2334 6.04747 13.5263 5.75458L18.0006 1.28033ZM1.4379 14.1729C0.820133 14.1729 0.319336 14.6737 0.319336 15.2914C0.319336 15.9092 0.820132 16.41 1.4379 16.41H17.0978C17.7155 16.41 18.2163 15.9092 18.2163 15.2914C18.2163 14.6737 17.7155 14.1729 17.0978 14.1729H1.4379ZM1.4379 8.5651C0.820134 8.5651 0.319336 9.0659 0.319336 9.68366C0.319336 10.3014 0.820131 10.8022 1.4379 10.8022H17.0978C17.7155 10.8022 18.2163 10.3014 18.2163 9.68366C18.2163 9.0659 17.7155 8.5651 17.0978 8.5651H1.4379ZM1.4379 2.98724C0.820133 2.98724 0.319336 3.48803 0.319336 4.1058C0.319336 4.72356 0.820133 5.22436 1.4379 5.22436H5.16644C5.78421 5.22436 6.285 4.72356 6.285 4.1058C6.285 3.48803 5.78421 2.98724 5.16644 2.98724H1.4379Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  20: ({ color }: { color: string }) => (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9125 1.28033C20.2054 0.987437 20.2054 0.512563 19.9125 0.21967C19.6196 -0.0732233 19.1448 -0.0732233 18.8519 0.21967L14.3833 4.68825L12.4142 2.71913C12.1213 2.42623 11.6464 2.42623 11.3535 2.71913C11.0606 3.01202 11.0606 3.48689 11.3535 3.77979L13.853 6.27925C14.1459 6.57214 14.6207 6.57214 14.9136 6.27925L19.9125 1.28033ZM1.46994 15.7469C0.779738 15.7469 0.220215 16.3065 0.220215 16.9967C0.220215 17.6869 0.779737 18.2464 1.46994 18.2464H18.9661C19.6564 18.2464 20.2159 17.6869 20.2159 16.9967C20.2159 16.3065 19.6564 15.7469 18.9661 15.7469H1.46994ZM1.46994 9.4816C0.779738 9.4816 0.220215 10.0411 0.220215 10.7313C0.220215 11.4215 0.779737 11.9811 1.46994 11.9811H18.9661C19.6564 11.9811 20.2159 11.4215 20.2159 10.7313C20.2159 10.0411 19.6564 9.4816 18.9661 9.4816H1.46994ZM1.46994 3.24966C0.779738 3.24966 0.220215 3.80918 0.220215 4.49939C0.220215 5.18959 0.779737 5.74912 1.46994 5.74912H5.63571C6.32591 5.74912 6.88544 5.18959 6.88544 4.49939C6.88544 3.80918 6.32591 3.24966 5.63571 3.24966H1.46994Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.7453 1.28033C24.0382 0.987437 24.0382 0.512563 23.7453 0.21967C23.4524 -0.0732233 22.9775 -0.0732233 22.6846 0.21967L17.2149 5.68934L14.7453 3.21967C14.4524 2.92678 13.9775 2.92678 13.6846 3.21967C13.3917 3.51256 13.3917 3.98744 13.6846 4.28033L16.6846 7.28033C16.8253 7.42098 17.016 7.5 17.2149 7.5C17.4138 7.5 17.6046 7.42098 17.7453 7.28033L23.7453 1.28033ZM1.71582 18.75C0.887393 18.75 0.21582 19.4216 0.21582 20.25C0.21582 21.0784 0.887393 21.75 1.71582 21.75H22.7158C23.5442 21.75 24.2158 21.0784 24.2158 20.25C24.2158 19.4216 23.5442 18.75 22.7158 18.75H1.71582ZM1.71582 11.23C0.887393 11.23 0.21582 11.9015 0.21582 12.73C0.21582 13.5584 0.887393 14.23 1.71582 14.23H22.7158C23.5442 14.23 24.2158 13.5584 24.2158 12.73C24.2158 11.9015 23.5442 11.23 22.7158 11.23H1.71582ZM1.71582 3.75C0.887393 3.75 0.21582 4.42157 0.21582 5.25C0.21582 6.07843 0.887393 6.75 1.71582 6.75H6.71582C7.54425 6.75 8.21582 6.07843 8.21582 5.25C8.21582 4.42157 7.54425 3.75 6.71582 3.75H1.71582Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.6618 1.28033C25.9547 0.987437 25.9547 0.512563 25.6618 0.21967C25.3689 -0.0732233 24.894 -0.0732233 24.6011 0.21967L18.6314 6.18934L15.9118 3.46967C15.6189 3.17678 15.144 3.17678 14.8511 3.46967C14.5582 3.76256 14.5582 4.23744 14.8511 4.53033L18.1011 7.78033C18.394 8.07322 18.8689 8.07322 19.1618 7.78033L25.6618 1.28033ZM1.84082 20.25C0.943358 20.25 0.21582 20.9775 0.21582 21.875C0.21582 22.7725 0.943358 23.5 1.84082 23.5H24.5908C25.4883 23.5 26.2158 22.7725 26.2158 21.875C26.2158 20.9775 25.4883 20.25 24.5908 20.25H1.84082ZM1.84082 12.1033C0.943358 12.1033 0.21582 12.8308 0.21582 13.7283C0.21582 14.6257 0.943358 15.3533 1.84082 15.3533H24.5908C25.4883 15.3533 26.2158 14.6257 26.2158 13.7283C26.2158 12.8308 25.4883 12.1033 24.5908 12.1033H1.84082ZM1.84082 4C0.943358 4 0.21582 4.72754 0.21582 5.625C0.21582 6.52246 0.943358 7.25 1.84082 7.25H7.25749C8.15495 7.25 8.88249 6.52246 8.88249 5.625C8.88249 4.72754 8.15495 4 7.25749 4H1.84082Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  28: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.5783 1.28033C27.8711 0.987437 27.8711 0.512563 27.5783 0.21967C27.2854 -0.0732233 26.8105 -0.0732233 26.5176 0.21967L20.0479 6.68934L17.0783 3.71967C16.7854 3.42678 16.3105 3.42678 16.0176 3.71967C15.7247 4.01256 15.7247 4.48744 16.0176 4.78033L19.5176 8.28033C19.8105 8.57322 20.2854 8.57322 20.5783 8.28033L27.5783 1.28033ZM1.96582 21.75C0.999322 21.75 0.21582 22.5335 0.21582 23.5C0.21582 24.4665 0.999322 25.25 1.96582 25.25H26.4658C27.4323 25.25 28.2158 24.4665 28.2158 23.5C28.2158 22.5335 27.4323 21.75 26.4658 21.75H1.96582ZM1.96582 12.9766C0.999322 12.9766 0.21582 13.7601 0.21582 14.7266C0.21582 15.6931 0.999322 16.4766 1.96582 16.4766H26.4658C27.4323 16.4766 28.2158 15.6931 28.2158 14.7266C28.2158 13.7601 27.4323 12.9766 26.4658 12.9766H1.96582ZM1.96582 4.25C0.999322 4.25 0.21582 5.0335 0.21582 6C0.21582 6.9665 0.99932 7.75 1.96582 7.75H7.79915C8.76565 7.75 9.54915 6.9665 9.54915 6C9.54915 5.0335 8.76565 4.25 7.79916 4.25H1.96582Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.4948 1.28033C29.7876 0.987437 29.7876 0.512563 29.4948 0.21967C29.2019 -0.0732233 28.727 -0.0732233 28.4341 0.21967L21.4644 7.18934L18.2448 3.96967C17.9519 3.67678 17.477 3.67678 17.1841 3.96967C16.8912 4.26256 16.8912 4.73744 17.1841 5.03033L20.9341 8.78033C21.227 9.07322 21.7019 9.07322 21.9948 8.78033L29.4948 1.28033ZM2.09082 23.25C1.05529 23.25 0.21582 24.0895 0.21582 25.125C0.21582 26.1605 1.05529 27 2.09082 27H28.3408C29.3764 27 30.2158 26.1605 30.2158 25.125C30.2158 24.0895 29.3764 23.25 28.3408 23.25H2.09082ZM2.09082 13.8499C1.05529 13.8499 0.21582 14.6894 0.21582 15.7249C0.21582 16.7605 1.05529 17.5999 2.09082 17.5999H28.3408C29.3764 17.5999 30.2158 16.7605 30.2158 15.7249C30.2158 14.6894 29.3764 13.8499 28.3408 13.8499H2.09082ZM2.09082 4.5C1.05529 4.5 0.21582 5.33947 0.21582 6.375C0.21582 7.41053 1.05529 8.25 2.09082 8.25H8.34082C9.37636 8.25 10.2158 7.41053 10.2158 6.375C10.2158 5.33947 9.37636 4.5 8.34082 4.5H2.09082Z"
        fill="#303030"
        stroke={color}
      />
    </svg>
  )
};
