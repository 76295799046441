/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import * as React from 'react';
import NoNotificationIcon from '../../../assets/image/no-notification.svg';
export function NoNotificationComponent({ title }: { title: string }) {
  return (
    <div className='no-notification'>
      <img width='100%' height='64' src={NoNotificationIcon} alt={title} />
      {title}
    </div>
  );
}
