/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { Divider } from '@scuf/common';
import './NotificationComponent.scss';
import {
  NiagaraButton2,
  NiagaraIcon,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { notificationsMockData } from './mockData';
import { NotificationItem } from './NotificationItem';
import { NoNotificationComponent } from './NoNotificationComponent';

interface NotificationComponentProps {
  onCloseNotification: (dataClose?: string) => void;
}

export function NotificationComponent({ onCloseNotification }: NotificationComponentProps) {
  const [dataClose, setDataClose] = useState<boolean>(false);
  return (
    <div className='notification-model-container'>
      <div className='notification-header'>
        <span className='setting-icon'>
          <NiagaraButton2
            type='secondary-link'
            icon='Settings'
            iconSize={16}
            iconColor='#0071B3'
            onClick={() => onCloseNotification('ConfigureNotification')}
          />
        </span>
        <span className='title'>Notifications</span>
        <span className='close-icon'>
          <NiagaraButton2
            type='secondary-link'
            icon='Close'
            iconColor='#606060'
            onClick={() => onCloseNotification()}
          />
        </span>
      </div>
      <Divider fitted={true} />
      <div className='notification-header'>
        <div className='notification-read'>
          <button className='all-btn'>All ({notificationsMockData.numberOfElements})</button>
          <button className='unread-btn'>Unread (0)</button>
        </div>
        <div className='MarkRead'>
          <NiagaraIcon name='MarkAllRead' size={16} /> Mark all as read
        </div>
      </div>
      <Divider fitted={true} />
      <div className='card-content'>
        {notificationsMockData.numberOfElements > 0 ? (
          notificationsMockData.content.map((n, i) => (
            <>
              <NotificationItem key={`notification-item${i}`} {...n} />
              {i !== notificationsMockData.content.length - 1 && <Divider fitted={true} />}
            </>
          ))
        ) : (
          <NoNotificationComponent title='No notifications' />
        )}
      </div>
    </div>
  );
}
