/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React from 'react';
import {
  NiagaraBadge,
  NiagaraButton2,
  NiagaraIcon,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { getBadgeProperty, NotificationProps } from './NotificationConstant';
export function NotificationItem({
  subject,
  generatedTime,
  content,
  expireInDays,
  isUnread,
  isSuccess,
}: NotificationProps) {
  const getBadge = (days: number, isSuccess: boolean) => {
    //TODO this logic will change after api implementation
    const badgeProperty = getBadgeProperty(days, isSuccess);
    return badgeProperty && <NiagaraBadge {...badgeProperty} />;
  };
  return (
    <div className={`notifications-item${isUnread ? ' unread' : ' read'}`}>
      {isUnread && (
        <div className='unread-icon'>
          <NiagaraIcon name='Status' color='#005EAC' size={8} />
        </div>
      )}
      <div className='notification-content'>
        <div className='notification-body'>
          <div className='title-content'>
            <div className='subject'>{subject}</div>
            <div className='description'>
              <span>Edwin_Device_45</span> {content}.
            </div>
          </div>
          <div className='expiray-badge'>
            <div className='mark-icon'>
              <NiagaraIcon name='MarkAllRead' color='#303030' size={16} />
            </div>
            <div className='notification-badge'>{getBadge(expireInDays, isSuccess)}</div>
          </div>
        </div>
        <div className='notification-footer'>
          <div className='expiry-date'>{generatedTime}</div>
          <div className='navigation-link'>
            <NiagaraButton2 type='primary-link' content={isSuccess ? 'View details' : 'Renew'} />
          </div>
        </div>
      </div>
    </div>
  );
}
