(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@scuf/datatable"), require("@scuf/common"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define("@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib", ["react", "@scuf/datatable", "@scuf/common", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib"] = factory(require("react"), require("@scuf/datatable"), require("@scuf/common"), require("lodash"));
	else
		root["@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib"] = factory(root["react"], root["@scuf/datatable"], root["@scuf/common"], root["lodash"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__1226__, __WEBPACK_EXTERNAL_MODULE__149__, __WEBPACK_EXTERNAL_MODULE__150__) => {
return 